.primary-350-button {
  @apply bg-primary-chocolate text-primary-cream font-semibold h-auto border-none rounded-md py-[0.85rem] xsm:px-[6rem] px-[4rem] w-full mt-3 disabled:opacity-50 disabled:cursor-not-allowed hover:opacity-60 cursor-pointer;

  &.disabled {
    @apply pointer-events-none bg-grey-500;
  }
}

.secondary-350-button {
  @apply text-black font-semibold h-auto border-[1px] border-black rounded-md w-full p-3 enabled:hover:bg-primary-chocolate enabled:hover:text-white cursor-pointer;

  &.disabled {
    @apply pointer-events-none bg-grey-500;
  }
}

.blue-button {
  @apply bg-sky-500 text-black font-semibold h-auto border-none rounded-md py-[0.85rem]  px-[2rem]  mt-3 enabled:hover:bg-sky-900 enabled:hover:text-black cursor-pointer disabled:cursor-not-allowed disabled:opacity-50;
}

.profile-button {
  @apply border-2 text-body rounded-md border-black max-w-[18rem] p-3;
}

.skills-button {
  @apply text-white text-button mr-2 mb-2 py-1 px-2.5 min-h-fit rounded-md min-w-fit;
}

.input-button-tag {
  @apply bg-primary-forest text-center text-white text-xsr sm:text-button sm:mr-2 mb-1 sm:mb-2 sm:py-1 px-1 sm:px-2.5 min-h-fit min-w-fit rounded-md;
}

.delete-tag-button {
  @apply text-md sm:text-h4 cursor-pointer;
}

.rounded-button {
  @apply border border-primary-chocolate rounded-3xl w-fit hover:bg-primary-chocolate hover:text-white text-xsr px-[0.4rem] py-[0.15rem] sm:text-body sm:px-3 sm:py-1.5;
}

.header-btn {
  @apply bg-transparent border border-white text-white text-xs sm:px-4 sm:py-2.5 px-2 py-1.5 sm:mx-2 mx-1 rounded-md hover:bg-chocolate-300 hover:border-transparent justify-between;
}

.outlined-button {
  @apply flex flex-row justify-center items-center gap-2 bg-transparent text-xsr sm:text-body text-black border border-black h-[1.875rem] rounded py-1 sm:py-1.5 px-1 sm:px-3 hover:bg-primary-chocolate hover:text-white disabled:cursor-not-allowed disabled:hover:text-black disabled:hover:bg-transparent;
}

.dark-outlined-button {
  @apply flex md:flex-row flex-col items-center justify-center gap-2 bg-primary-chocolate text-white text-[0.75rem] md:text-button md:h-[30px] rounded py-1.5 md:px-3 px-2 hover:bg-white hover:text-primary-chocolate disabled:hover:bg-primary-chocolate disabled:hover:text-white hover:border hover:border-black disabled:cursor-not-allowed;
}

.platform-outlined-button {
  @apply flex md:flex-row flex-col items-center justify-center gap-2 bg-primary-background text-white text-[0.75rem] md:text-button md:h-[30px] rounded py-1.5 md:px-3 px-2 hover:bg-white hover:text-primary-chocolate disabled:hover:bg-primary-chocolate disabled:hover:text-white hover:border hover:border-black disabled:cursor-not-allowed;
}

.peach-outlined-button {
  @apply flex md:flex-row flex-col items-center justify-center gap-2 bg-peach-800 text-white text-[0.75rem] md:text-button md:h-[30px] rounded py-1.5 md:px-3 px-2 hover:bg-white hover:text-primary-chocolate disabled:hover:bg-primary-chocolate disabled:hover:text-white hover:border hover:border-black disabled:cursor-not-allowed;
}

.cream-outlined-button {
  @apply flex md:flex-row flex-col items-center justify-center gap-2 bg-primary-cream lg:bg-secondary-background text-primary-chocolate text-[0.75rem] md:text-button md:h-[30px] rounded py-1.5 md:px-3 px-2 hover:bg-white hover:text-primary-chocolate disabled:hover:bg-primary-chocolate disabled:hover:text-white hover:border hover:border-black disabled:cursor-not-allowed;
}

.outlined-green-button {
  @apply flex flex-row items-center gap-2 bg-transparent text-green-600 text-[0.75rem]  border border-green-500 rounded py-1.5 px-2 hover:bg-green-600 hover:text-white md:px-3 md:text-button md:h-[30px];
}

.red-button {
  @apply flex flex-row items-center gap-2 bg-transparent text-white border bg-red-800 h-[30px] rounded py-1.5 px-3 hover:bg-red-600 hover:text-white;
}

.teal-outlined-button {
  @apply flex md:flex-row flex-col items-center justify-center gap-2 bg-[#599E9E] text-white text-[0.75rem] md:text-button md:h-[30px] rounded py-1.5 md:px-3 px-2 hover:bg-white hover:text-primary-chocolate disabled:hover:bg-primary-chocolate disabled:hover:text-white hover:border hover:border-black disabled:cursor-not-allowed;
}
