/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/styles/mixins";

@import "assets/styles/accordion";
@import "assets/styles/buttons";
@import "assets/styles/date-picker";
@import "assets/styles/inputs";
@import "assets/styles/scrollbar";

@import url("https://fonts.googleapis.com/css?family=Manrope");

* {
  font-family: "Manrope", "sans-serif";
  // Based on https://github.com/nolimits4web/swiper/issues/3599
  // Was only change that fixed carousel width incorrect
  min-width: 0;
}

.form {
  @apply w-full;
}

.form-controls-container {
  @apply flex flex-col w-full items-center;
}

.hyperlink {
  @apply text-md my-2 text-center underline;
}

.primary-350-button {
  @apply bg-black text-primary-cream font-semibold h-auto border-none rounded-md py-[0.85rem] xsm:px-[6rem] px-[4rem] w-full  enabled:hover:bg-sky-900 enabled:hover:text-black cursor-pointer disabled:cursor-not-allowed disabled:opacity-50;
}

.input {
  @apply h-14 mb-4 shadow-auth-input border-none bg-sky-100 w-full rounded-md py-[10px] pr-[60px] pl-[10px];
}

.blue-button {
  @apply bg-sky-500 text-black font-semibold h-auto border-none rounded-md py-[0.85rem]  px-[2rem]  mt-3 enabled:hover:bg-sky-900 enabled:hover:text-black cursor-pointer disabled:cursor-not-allowed disabled:opacity-50;
}

.secondary-input {
  @apply h-12 mb-2 border border-black border-opacity-25 bg-white w-full rounded-md py-[0.5rem] pr-[2rem] pl-[0.5rem] sm:py-[0.625rem] sm:pr-[3.75rem] sm:pl-[0.625rem] sm:mb-4 sm:h-14;
}

.tertiary-input {
  @apply h-8 mb-4 border border-primary-chocolate bg-white w-full py-[10px] pr-[60px] pl-[10px];
}

.input-with-prefix {
  @apply h-14 mb-4 border border-l-0 border-black border-opacity-25 bg-white rounded-r-md w-10/12 py-[10px] pr-[60px] pl-[10px];
}

.input-prefix {
  @apply h-14 mb-4 border border-r-0 border-primary-chocolate bg-gray-100 rounded-l-md w-2/12 py-[10px] px-[10px];
}

.input-container {
  @apply flex flex-col mb-2 w-full sm:mb-4;
}

.auth-page-container {
  @apply flex flex-col justify-center items-center h-full max-w-full w-full;
}

.auth-form-container {
  @apply flex flex-col items-center mt-8;

  .logo-image {
    @apply h-auto w-[8rem];
  }
}

.auth-form-title {
  @apply text-body sm:text-h4 sm:text-center;
}

.error-text {
  @apply text-xsr sm:text-tags pt-1 text-error break-normal max-w-fit;
}

.success-text {
  @apply text-primary-forest text-xs mb-2 break-normal max-w-[19rem];
}

.success-text-box {
  @apply bg-green-100 mt-2 rounded-md p-2 w-full;
}

.failure-text-box {
  @apply bg-red-100 mt-2 rounded-md p-2 w-full;
}

.profile-section {
  @apply text-md border-b py-5 pr-5 border-inactive;
}

.modal-template {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-md max-h-full;
}

.tab-container {
  @apply w-full;
}

.empty-chat-background {
  @apply h-full bg-local bg-no-repeat bg-center lg:bg-30 bg-16;
  background-image: url("/assets/images/chat-background.png");
}

.general-padding {
  @apply px-6 sm:px-5 lg:px-24;
}

.editor-js-content {
  @apply text-md;
  h1 {
    @apply text-h1 pt-0;
  }

  h2 {
    @apply text-h2 pt-0;
  }

  h3 {
    @apply text-h3 pt-0;
  }

  h4 {
    @apply text-h4 pt-0;
  }

  h5 {
    @apply text-h4 pt-0;
  }

  h6 {
    @apply text-h4 pt-0;
  }

  .tc-table {
    @apply border-l border-gray-200;
  }

  .ce-block {
    @apply w-fit;
  }

  .ce-block__content {
    @apply lg:max-w-screen-xl;
  }

  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }

  .fr-view-element {
    @apply max-w-screen-xsm w-screen p-0 xsm:max-w-screen-xsm 2xsm:max-w-screen-2xsm md:max-w-screen-xl md:p-[1.25rem];
  }

  // The first div (e.g .m, .aa, .aj, .y etc...) in fr-view-element class always has a class with a defined margin.
  // Here I am controlling the margin on mobile devices.
  .fr-view-element > div {
    @apply my-0 mx-2 md:my-20 md:mx-16;
  }
}

.default-card {
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.26);
  @apply p-6 rounded-lg;
}
