// Adapted from https://dev.to/xtrp/how-to-create-a-beautiful-custom-scrollbar-for-your-site-in-plain-css-1mjg
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-grey-300 rounded-2xl border-8 border-transparent border-solid bg-clip-content;
}
