.datepicker-cell {
  &.selected {
    @apply bg-primary-chocolate;
  }
}

.datepicker-controls {
  .today-btn {
    @apply bg-primary-chocolate hover:bg-chocolate-300 focus:ring-4 focus:ring-chocolate-100;
  }
}

.datepicker-input {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-chocolate-500 focus:border-chocolate-500 block w-full ps-10 p-2.5;
}

.datetimepicker-input,
.mobile-datetimepicker-input {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-[0.2rem] w-full max-w-[12.5rem];
}
