.input {
  @apply text-xsr sm:text-body h-14 mb-4 shadow-auth-input border-none bg-sky-100 w-full rounded-md py-[10px] pr-[60px] pl-[10px];
}
.secondary-input {
  @apply text-xsr h-14 mb-4 border border-primary-chocolate  bg-white w-full rounded-md py-[10px] pr-[60px] pl-[10px] sm:text-body;
}

.tertiary-input {
  @apply text-xsr h-8 mb-4 border border-primary-chocolate bg-white w-full py-[10px] pr-[60px] pl-[10px] sm:text-body;
}

.input-with-prefix {
  @apply text-xsr max-h-10 sm:mb-4 border border-l-0 border-primary-chocolate bg-white rounded-r w-10/12 pt-2.5 pr-[60px] pl-[10px] sm:text-body;
}

.input-prefix {
  @apply text-xsr h-14 mb-4 border border-r-0 border-primary-chocolate bg-gray-100 rounded-l-md w-2/12 py-[10px] px-[10px] sm:text-body;
}

.input-container {
  @apply flex flex-col mb-4 w-full;
}

.thin-secondary-input {
  @apply max-h-10 text-xsr sm:text-body sm:mb-4 border border-primary-chocolate border-opacity-30 bg-white w-full rounded py-2.5 px-3;
}

.thin-secondary-text-area {
  @apply mb-4 border border-primary-chocolate border-opacity-30 bg-white w-full rounded py-2.5 px-3;
}
