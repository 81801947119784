.accordion-item-heading {
  button {
    @apply flex items-center justify-between w-full p-5 font-medium text-left text-black border-b border-gray-200 hover:bg-gray-100 gap-3;
  }

  &.accordion-active {
    button {
      @apply bg-none font-extrabold;
    }
  }
}

.accordion-item-body {
  @apply p-5;
}
